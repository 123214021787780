var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background",staticStyle:{"min-width":"50vw","width":"100%","height":"100%"}},[_c('v-subheader',{staticStyle:{"position":"relative"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.TABLE_HEADER'))+" "),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-0",style:({
        transition: 'max-width 0.5s ease-in-out',
        maxWidth: _vm.searchTable !== false ? '300px' : 0
      }),attrs:{"value":_vm.searchTable || '',"dense":"","hide-details":"","single-line":"","autofocus":"","label":_vm.$vuetify.lang.t('$vuetify.firmware.SEARCH'),"placeholder":_vm.$vuetify.lang.t('$vuetify.firmware.SEARCH'),"type":"search"},on:{"input":function($event){_vm.$router
          .replace({
            query: Object.assign({}, _vm.$route.query, {search: ($event || '').trim()})
          })
          .catch(_vm.noop)},"blur":function($event){_vm.searchTable ||
          _vm.$router
            .replace({ query: Object.assign({}, _vm.$route.query, {search: undefined}) })
            .catch(_vm.noop)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.$router
          .replace({ query: Object.assign({}, _vm.$route.query, {search: undefined}) })
          .catch(_vm.noop)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pa-3"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"right":"0"},attrs:{"icon":"","small":"","absolute":""},on:{"click":function($event){_vm.searchTable !== false ||
                  _vm.$router
                    .replace({
                      query: Object.assign({}, _vm.$route.query, {search: ''})
                    })
                    .catch(_vm.noop)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiMagnify)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.SEARCH'))+" ")])])]},proxy:true}])}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialogShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiTablePlus))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.ADD_FIRMWARE'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.getFirmwareList}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiRefresh))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.REFRESH'))+" ")])])],1),_c('div',{style:(_vm.$vuetify.breakpoint.mdAndUp
        ? 'height: calc(100vh - 64px - 48px); position: relative;'
        : 'height: calc(100% - 48px); position: relative')},[_c('v-data-table',{style:(_vm.$vuetify.breakpoint.mdAndUp ? 'position: absolute; inset: 0' : ''),attrs:{"loading":_vm.loading,"headers":_vm.tableHeaders,"items":_vm.tableItems,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":"","search":_vm.searchTable || '',"custom-filter":function (value, search, item) { return JSON.stringify(item).toUpperCase().includes(search.toUpperCase()); },"fixed-header":"","height":"calc(100vh - 64px - 48px - 59px)","items-per-page":50,"footer-props":{
        itemsPerPageOptions: [50]
      }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('v-spacer',{staticStyle:{"height":"58px"}})]},proxy:true},{key:"item.Key",fn:function(ref){
      var item = ref.item;
return [_c('v-row',{staticClass:"flex-nowrap",staticStyle:{"min-height":"100%"},attrs:{"dense":"","no-gutters":"","align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({staticClass:"flex-nowrap",staticStyle:{"min-height":"100%","cursor":"pointer"},attrs:{"dense":"","no-gutters":"","align":"center"},on:{"click":function($event){_vm.$router.push(("/ota/jobs?search=" + (item.Key))).catch(_vm.noop)}}},'v-row',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiCloudSearch)+" ")]),_vm._v(" "+_vm._s(item.Key)+" "),_c('v-spacer')],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.OPEN_JOB'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openPresignedLink(item.Key)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiCloudDownload))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.DOWNLOAD_FORMWARE'))+" ")])])],1)]}},{key:"item.LastModified",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.LastModified))+" ")]}},{key:"item.Size",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("prettyBytes")(item.Size))+" ")]}},{key:"item.remove",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.removeFirmwareKey = item.Key}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiClose)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.REMOVE_FIRMWARE'))+" ")])])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500"},on:{"click:outside":_vm.resetDialog},model:{value:(_vm.dialogShow),callback:function ($$v) {_vm.dialogShow=$$v},expression:"dialogShow"}},[_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.dialogValid),callback:function ($$v) {_vm.dialogValid=$$v},expression:"dialogValid"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.ADD_FIRMWARE'))+" ")])]),_c('v-card-text',[_c('v-file-input',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.firmware.FILE_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.firmware.FILE_REQUIRED'),"rules":[_vm.requiredRule, _vm.sizeRule],"accept":".bin","show-size":"","clearable":"","autofocus":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.dialogFile),callback:function ($$v) {_vm.dialogFile=$$v},expression:"dialogFile"}}),_c('v-text-field',{attrs:{"value":_vm.dialogName || (_vm.dialogFile ? _vm.dialogFile.name : ''),"label":_vm.$vuetify.lang.t('$vuetify.firmware.NAME_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.firmware.NAME_REQUIRED'),"rules":[_vm.requiredRule],"required":""},on:{"input":function($event){_vm.dialogName = $event}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}])})],1),_c('v-card-actions',[_c('small',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.REQUIRED_FIELD'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.resetDialog}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.loading || !_vm.dialogValid},on:{"click":_vm.addFirmware}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.ADD'))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"value":!!_vm.removeFirmwareKey,"max-width":"400"},on:{"input":function($event){_vm.removeFirmwareKey = ''}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.REMOVE_FIRMWARE'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.removeFirmwareKey)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.CONFIRM_REMOVE'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.removeFirmwareKey = ''}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.removeFirmware}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.firmware.REMOVE'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }