












































































































































































































































































































import {
  addFirmware,
  generatePresignedLink,
  getFirmwareList,
  removeFirmware
} from '../../api/admin';
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  mdiRefresh,
  mdiMagnify,
  mdiTablePlus,
  mdiClose,
  mdiCloudSearch,
  mdiCloudDownload
} from '@mdi/js';
import type { ValidationRule } from '../../typings';
import { noop } from 'vue-class-component/lib/util';
import { SettingsModule } from '../../plugins/store';
import { getModule } from 'vuex-module-decorators';
import type { _Object } from '@aws-sdk/client-s3';
import FileSaver from 'file-saver';
import { Hub } from '@aws-amplify/core';

const settingsStore: SettingsModule = getModule(SettingsModule);

@Component
export default class FirmwareTableView extends Vue {
  private readonly mdiRefresh: string = mdiRefresh;
  private readonly mdiTablePlus: string = mdiTablePlus;
  private readonly mdiClose: string = mdiClose;
  private readonly mdiMagnify: string = mdiMagnify;
  private readonly mdiCloudSearch: string = mdiCloudSearch;
  private readonly mdiCloudDownload: string = mdiCloudDownload;
  private readonly noop: typeof noop = noop;
  private readonly requiredRule: ValidationRule = (
    v: string | string[]
  ): true | string =>
    (!!v && (!Array.isArray(v) || !!v.length)) ||
    '$vuetify.firmware.REQUIRED';
  private readonly sizeRule: ValidationRule = (v: File): true | string =>
    !v || v.size < 10 * 1024 * 1024 || '$vuetify.firmware.TOO_LARGE';

  private dialogShow: boolean = false;
  private dialogValid: boolean = false;
  private dialogName: string = '';
  private dialogFile: File | null = null;
  private loading: boolean = true;
  private sortBy: string = 'LastModified';
  private sortDesc: boolean = true;
  private removeFirmwareKey: string = '';
  private tableItems: _Object[] = [];

  private get tableHeaders(): Array<{
    text: string;
    value: string;
    sortable: boolean;
    width?: string | number;
    align?: 'start' | 'center' | 'end';
  }> {
    return [
      {
        text: this.$vuetify.lang.t('$vuetify.firmware.NAME'),
        value: 'Key',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.firmware.SIZE'),
        value: 'Size',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.firmware.MODIFIED'),
        value: 'LastModified',
        sortable: true
      },
      {
        text: '',
        value: 'remove',
        sortable: false,
        width: '0',
        align: 'end'
      }
    ];
  }

  private get showConsole(): boolean {
    return settingsStore.console;
  }

  @Prop({ type: [String, Boolean], default: false })
  private readonly searchTable!: string | false;

  private mounted(): void {
    this.getFirmwareList();
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private getFirmwareList(): void {
    this.loading = true;
    getFirmwareList()
      .then((list: _Object[]): void => void (this.tableItems = list))
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private addFirmware(): void {
    if (!this.dialogFile) {
      return;
    }
    this.loading = true;
    const fileName: string = this.dialogName || this.dialogFile.name;
    const index: number = this.tableItems.findIndex(
      (item: _Object): boolean => item.Key === fileName
    );
    addFirmware(fileName, this.dialogFile)
      .then((file: _Object): void => {
        if (index < 0) {
          this.tableItems.push(file);
        } else {
          this.tableItems.splice(index, 1, file);
        }
        this.resetDialog();
        this.sortBy = 'LastModified';
        this.sortDesc = true;
      })
      .catch((error: Error): void => {
        this.dialogShow = false;
        this.showError(error.message);
      })
      .finally((): void => void (this.loading = false));
  }

  private removeFirmware(): void {
    const index: number = this.tableItems.findIndex(
      (item: _Object): boolean => item.Key === this.removeFirmwareKey
    );
    if (index < 0) {
      return;
    }
    const key: string = this.removeFirmwareKey;
    this.loading = true;
    this.removeFirmwareKey = '';
    removeFirmware(key)
      .then((): void => void this.tableItems.splice(index, 1))
      .catch((error: Error): void => void this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private async openPresignedLink(key: string): Promise<void> {
    FileSaver.saveAs(await generatePresignedLink(key), key);
  }

  private resetDialog(): void {
    this.dialogShow = false;
    this.dialogName = '';
    this.dialogFile = null;
  }
}
